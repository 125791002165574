<template>
  <media-modal
    :visible="show"
    :centered="true"
    size="default"
  >
    <template slot="modalHeader">
      Move item
    </template>

    <template slot="modalBody">
      <div
        v-if="loading"
        class="text-center">
        <span class="spinner icon-spinner2" />Loading...
      </div>

      <div v-else>
        <div class="modal-body">
          <div class="form-group">
            <label>Item name</label>
            <p class="static">
              {{ getItemName(currentFile) }}
            </p>
          </div>

          <div class="form-group">
            <label>Move to</label>
            <select
              id="newFolderLocation"
              ref="newFolderLocation"
              v-model="newFolderLocation"
              class="form-control"
              name="newFolderLocation"
              @keyup.enter="moveItem()"
            >
              <!-- eslint-disable vue/no-v-html -->
              <option
                v-for="(name, path) in allDirectories"
                :key="path"
                :value="path"
                v-html="name"
              />
              <!--eslint-enable-->
            </select>
          </div>

          <media-errors :errors="errors" />
        </div>
      </div>
    </template>

    <template slot="modalFooter">
      <div class="w-100">
        <div class="float-right">
          <b-button
            variant="primary"
            size="sm"
            class="mr-1"
            @click="moveItem()"
          >
            Apply
          </b-button>
          <b-button
            variant="secondary"
            size="sm"
            @click="close()"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </template>
  </media-modal>
</template>

<script>
import { nextTick } from '@vue/composition-api'
import axiosIns from '@/libs/axios'
import fileManagerMixin from '@/modules/mixins/fileManager'
import MediaModal from '@/views/media-manager/MediaModal.vue'
import MediaErrors from './MediaErrors.vue'

export default {
  components: {
    MediaModal,
    'media-errors': MediaErrors
  },

  mixins: [fileManagerMixin],

  props: {
    currentPath: {
      default: '',
      type: String
    },

    currentFile: {
      default() {
        return {}
      },
      type: [Object, Boolean]
    },

    /**
     * Default route prefix
     */
    prefix: {
      default: '/',
      type: String
    },

    show: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {
      allDirectories: {},
      errors: [],
      newFolderLocation: null,
      loading: false,
      size: 'modal-md'
    }
  },

  watch: {
    show(open) {
      if (open) {
        this.open()
      }
    }
  },

  mounted() {
    document.addEventListener('keydown', e => {
      if (this.show && e.keyCode === 13) {
        this.moveItem()
      }
    })
  },

  methods: {
    close() {
      this.newFolderName = null
      this.loading = false
      this.$emit('media-modal-close')
    },

    open() {
      axiosIns.get(`${this.prefix}browser/directories`).then(
        response => {
          this.newFolderLocation = this.currentPath
          this.allDirectories = response.data
        },
        response => {
          const error = response.data.error
            ? response.data.error
            : response.statusText
          this.mediaManagerNotify(error, 'danger')
        }
      )

      nextTick(() => {
        this.$refs.newFolderLocation.focus()
      })
    },

    moveItem() {
      const data = {
        path: this.currentPath,
        currentItem: this.getItemName(this.currentFile),
        newPath: this.newFolderLocation,
        type: this.isFolder(this.currentFile) ? 'Folder' : 'File'
      }

      this.loading = true
      axiosIns
        .post(`${this.prefix}browser/move`, data)
        .then(response => {
          this.$emit('reload-folder')
          window.eventHub.$emit(
            'media-manager-notification',
            response.data.success
          )
          this.close()
        })
        .catch(error => {
          this.errors = error.response.data.error
            ? error.response.data.error
            : error.response.statusText
          this.loading = false
        })
    }
  }
}
</script>
